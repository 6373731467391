import { h } from 'preact';
import ItemIcon from "./ItemIcon.js";
function GridItem(_ref) {
  var _file$data$name, _file$data$name2;
  let {
    file,
    toggleCheckbox,
    className,
    isDisabled,
    restrictionError,
    showTitles,
    children = null,
    i18n
  } = _ref;
  return h("li", {
    className: className,
    title: isDisabled && restrictionError ? restrictionError : undefined
  }, h("input", {
    type: "checkbox",
    className: "uppy-u-reset uppy-ProviderBrowserItem-checkbox uppy-ProviderBrowserItem-checkbox--grid",
    onChange: toggleCheckbox,
    name: "listitem",
    id: file.id,
    checked: file.status === 'checked',
    disabled: isDisabled,
    "data-uppy-super-focusable": true
  }), h("label", {
    htmlFor: file.id,
    "aria-label": (_file$data$name = file.data.name) != null ? _file$data$name : i18n('unnamed'),
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, h(ItemIcon, {
    itemIconString: file.data.thumbnail || file.data.icon
  }), showTitles && ((_file$data$name2 = file.data.name) != null ? _file$data$name2 : i18n('unnamed')), children));
}
export default GridItem;