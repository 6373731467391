import { h } from 'preact';
import classNames from 'classnames';
import { useMemo } from 'preact/hooks';
import getNumberOfSelectedFiles from "./utils/PartialTreeUtils/getNumberOfSelectedFiles.js";
export default function FooterActions(_ref) {
  let {
    cancelSelection,
    donePicking,
    i18n,
    partialTree,
    validateAggregateRestrictions
  } = _ref;
  const aggregateRestrictionError = useMemo(() => {
    return validateAggregateRestrictions(partialTree);
  }, [partialTree, validateAggregateRestrictions]);
  const nOfSelectedFiles = useMemo(() => {
    return getNumberOfSelectedFiles(partialTree);
  }, [partialTree]);
  if (nOfSelectedFiles === 0) {
    return null;
  }
  return h("div", {
    className: "uppy-ProviderBrowser-footer"
  }, h("div", {
    className: "uppy-ProviderBrowser-footer-buttons"
  }, h("button", {
    className: classNames('uppy-u-reset uppy-c-btn uppy-c-btn-primary', {
      'uppy-c-btn--disabled': aggregateRestrictionError
    }),
    disabled: !!aggregateRestrictionError,
    onClick: donePicking,
    type: "button"
  }, i18n('selectX', {
    smart_count: nOfSelectedFiles
  })), h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-c-btn-link",
    onClick: cancelSelection,
    type: "button"
  }, i18n('cancel'))), aggregateRestrictionError && h("div", {
    className: "uppy-ProviderBrowser-footer-error"
  }, aggregateRestrictionError));
}