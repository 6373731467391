/* eslint-disable react/destructuring-assignment */
import { h } from 'preact';
import classNames from 'classnames';
import User from "./User.js";
import Breadcrumbs from "../Breadcrumbs.js";
export default function Header(props) {
  return h("div", {
    className: "uppy-ProviderBrowser-header"
  }, h("div", {
    className: classNames('uppy-ProviderBrowser-headerBar', !props.showBreadcrumbs && 'uppy-ProviderBrowser-headerBar--simple')
  }, props.showBreadcrumbs && h(Breadcrumbs, {
    openFolder: props.openFolder,
    breadcrumbs: props.breadcrumbs,
    breadcrumbsIcon: props.pluginIcon && props.pluginIcon(),
    title: props.title,
    i18n: props.i18n
  }), h(User, {
    logout: props.logout,
    username: props.username,
    i18n: props.i18n
  })));
}