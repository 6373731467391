import { h, Fragment } from 'preact';
export default function Breadcrumbs(props) {
  const {
    openFolder,
    title,
    breadcrumbsIcon,
    breadcrumbs,
    i18n
  } = props;
  return h("div", {
    className: "uppy-Provider-breadcrumbs"
  }, h("div", {
    className: "uppy-Provider-breadcrumbsIcon"
  }, breadcrumbsIcon), breadcrumbs.map((folder, index) => {
    var _folder$data$name;
    return h(Fragment, null, h("button", {
      key: folder.id,
      type: "button",
      className: "uppy-u-reset uppy-c-btn",
      onClick: () => openFolder(folder.id)
    }, folder.type === 'root' ? title : (_folder$data$name = folder.data.name) != null ? _folder$data$name : i18n('unnamed')), breadcrumbs.length === index + 1 ? '' : ' / ');
  }));
}