/* eslint-disable react/jsx-props-no-spreading */
import { h } from 'preact';
import classNames from 'classnames';
import GridItem from "./components/GridItem.js";
import ListItem from "./components/ListItem.js";
export default function Item(props) {
  const {
    viewType,
    toggleCheckbox,
    showTitles,
    i18n,
    openFolder,
    file
  } = props;
  const restrictionError = file.type === 'folder' ? null : file.restrictionError;
  const isDisabled = !!restrictionError && file.status !== 'checked';
  const ourProps = {
    file,
    openFolder,
    toggleCheckbox,
    i18n,
    viewType,
    showTitles,
    className: classNames('uppy-ProviderBrowserItem', {
      'uppy-ProviderBrowserItem--disabled': isDisabled
    }, {
      'uppy-ProviderBrowserItem--noPreview': file.data.icon === 'video'
    }, {
      'uppy-ProviderBrowserItem--is-checked': file.status === 'checked'
    }, {
      'uppy-ProviderBrowserItem--is-partial': file.status === 'partial'
    }),
    isDisabled,
    restrictionError
  };
  switch (viewType) {
    case 'grid':
      return h(GridItem, ourProps);
    case 'list':
      return h(ListItem, ourProps);
    case 'unsplash':
      return h(GridItem, ourProps, h("a", {
        href: `${file.data.author.url}?utm_source=Companion&utm_medium=referral`,
        target: "_blank",
        rel: "noopener noreferrer",
        className: "uppy-ProviderBrowserItem-author",
        tabIndex: -1
      }, file.data.author.name));
    default:
      throw new Error(`There is no such type ${viewType}`);
  }
}