const afterOpenFolder = (oldPartialTree, discoveredItems, clickedFolder, currentPagePath, validateSingleFile) => {
  const discoveredFolders = discoveredItems.filter(i => i.isFolder === true);
  const discoveredFiles = discoveredItems.filter(i => i.isFolder === false);
  const isParentFolderChecked = clickedFolder.type === 'folder' && clickedFolder.status === 'checked';
  const folders = discoveredFolders.map(folder => ({
    type: 'folder',
    id: folder.requestPath,
    cached: false,
    nextPagePath: null,
    status: isParentFolderChecked ? 'checked' : 'unchecked',
    parentId: clickedFolder.id,
    data: folder
  }));
  const files = discoveredFiles.map(file => {
    const restrictionError = validateSingleFile(file);
    return {
      type: 'file',
      id: file.requestPath,
      restrictionError,
      status: isParentFolderChecked && !restrictionError ? 'checked' : 'unchecked',
      parentId: clickedFolder.id,
      data: file
    };
  });

  // just doing `clickedFolder.cached = true` in a non-mutating way
  const updatedClickedFolder = {
    ...clickedFolder,
    cached: true,
    nextPagePath: currentPagePath
  };
  const partialTreeWithUpdatedClickedFolder = oldPartialTree.map(folder => folder.id === updatedClickedFolder.id ? updatedClickedFolder : folder);
  const newPartialTree = [...partialTreeWithUpdatedClickedFolder, ...folders, ...files];
  return newPartialTree;
};
export default afterOpenFolder;