import { h } from 'preact';
import ItemIcon from "./ItemIcon.js";

// if folder:
//   + checkbox (selects all files from folder)
//   + folder name (opens folder)
// if file:
//   + checkbox (selects file)
//   + file name (selects file)

export default function ListItem(_ref) {
  var _file$data$name, _file$data$name2, _file$data$name3;
  let {
    file,
    openFolder,
    className,
    isDisabled,
    restrictionError,
    toggleCheckbox,
    showTitles,
    i18n
  } = _ref;
  return h("li", {
    className: className,
    title: file.status !== 'checked' && restrictionError ? restrictionError : undefined
  }, h("input", {
    type: "checkbox",
    className: "uppy-u-reset uppy-ProviderBrowserItem-checkbox",
    onChange: toggleCheckbox
    // for the <label/>
    ,
    name: "listitem",
    id: file.id,
    checked: file.status === 'checked',
    "aria-label": file.data.isFolder ? i18n('allFilesFromFolderNamed', {
      name: (_file$data$name = file.data.name) != null ? _file$data$name : i18n('unnamed')
    }) : null,
    disabled: isDisabled,
    "data-uppy-super-focusable": true
  }), file.data.isFolder ?
  // button to open a folder
  h("button", {
    type: "button",
    className: "uppy-u-reset uppy-c-btn uppy-ProviderBrowserItem-inner",
    onClick: () => openFolder(file.id),
    "aria-label": i18n('openFolderNamed', {
      name: (_file$data$name2 = file.data.name) != null ? _file$data$name2 : i18n('unnamed')
    })
  }, h("div", {
    className: "uppy-ProviderBrowserItem-iconWrap"
  }, h(ItemIcon, {
    itemIconString: file.data.icon
  })), showTitles && file.data.name ? h("span", null, file.data.name) : i18n('unnamed'))
  // label for a checkbox
  : h("label", {
    htmlFor: file.id,
    className: "uppy-u-reset uppy-ProviderBrowserItem-inner"
  }, h("div", {
    className: "uppy-ProviderBrowserItem-iconWrap"
  }, h(ItemIcon, {
    itemIconString: file.data.icon
  })), showTitles && ((_file$data$name3 = file.data.name) != null ? _file$data$name3 : i18n('unnamed'))));
}