const afterScrollFolder = (oldPartialTree, currentFolderId, items, nextPagePath, validateSingleFile) => {
  const currentFolder = oldPartialTree.find(i => i.id === currentFolderId);
  const newFolders = items.filter(i => i.isFolder === true);
  const newFiles = items.filter(i => i.isFolder === false);

  // just doing `scrolledFolder.nextPagePath = ...` in a non-mutating way
  const scrolledFolder = {
    ...currentFolder,
    nextPagePath
  };
  const partialTreeWithUpdatedScrolledFolder = oldPartialTree.map(folder => folder.id === scrolledFolder.id ? scrolledFolder : folder);
  const isParentFolderChecked = scrolledFolder.type === 'folder' && scrolledFolder.status === 'checked';
  const folders = newFolders.map(folder => ({
    type: 'folder',
    id: folder.requestPath,
    cached: false,
    nextPagePath: null,
    status: isParentFolderChecked ? 'checked' : 'unchecked',
    parentId: scrolledFolder.id,
    data: folder
  }));
  const files = newFiles.map(file => {
    const restrictionError = validateSingleFile(file);
    return {
      type: 'file',
      id: file.requestPath,
      restrictionError,
      status: isParentFolderChecked && !restrictionError ? 'checked' : 'unchecked',
      parentId: scrolledFolder.id,
      data: file
    };
  });
  const newPartialTree = [...partialTreeWithUpdatedScrolledFolder, ...folders, ...files];
  return newPartialTree;
};
export default afterScrollFolder;